<template>
	<div class="row mb-3">
		<div class="col-11">
			<h3>Radiobutton Image</h3>
			<p class="small">Radiobutton z odpowiedziami w formie graficznej, należy podać etykietę wyświetlającą się nad radiobuttonem oraz wartości dla każdego radiobuttona,
				pole Grafika jest opcjonalne (grafika wyświetli się pod etykietą). Pole feedback to wiadomość która wyświetli
				się na podsumowaniu w przypadku udzielenia błędnej odpowiedzi</p>
		</div>
		<div class="col">
			<div class="card">
				<div class="card-body">

					<div class="form-group">
						<label>Etykieta</label>
						<input v-model="field.title" type="text" class="form-control" required>
					</div>

					<div class="form-group">
						<label>Grafika (zalecana szerokość 840px)</label>
						<div v-if="field.image" class="position-relative w-50 mb-3">
							<BtnDelete @click="field.image = false"/>
							<img class="img-fluid rounded" :src="$store.state.backendUrl + 'storage/' + field.image[0]['token']" alt="">
						</div>
						<UploadFile v-else class="small contrast" :single="true" :element="graphicsEntity" @change="field.image = $event"></UploadFile>
					</div>

					<div v-if="summaryAnswers" class="form-group">
						<label>Feedback</label>
						<input v-model="field.feedback" type="text" class="form-control">
					</div>

					<p class="mb-2">Odpowiedzi: (zalecana szerokość 320px)</p>

					<div class="mb-3 bg-light card p-3" v-for="(item, index) in field.values">
						<div class="input-group mb-2">
							<div v-if="item.image" class="position-relative w-50">
								<img class="img-fluid rounded" :src="$store.state.backendUrl + 'storage/' + item.image[0]['token']" alt="">
								<BtnDelete @click="item.image = false"/>
							</div>
							<UploadFile v-else class="small contrast" :single="true" :element="graphicsEntity" @change="item.image = $event"></UploadFile>
						</div>
						<div v-if="summaryAnswers" class="input-group mb-2">
							<input type="text" class="form-control" v-model="item.feedback" placeholder="Feedback do odpowiedzi">
						</div>
						<div class="mb-3">
							<select v-if="summaryRange" v-model="item.summary" class="form-control w-50">
								<option :value="item.id" v-for="item in summaries">{{ item.title }}</option>
							</select>

							<div v-if="summaryPoints" class="input-group mb-2">
								<input type="text" class="form-control" v-model="item.points" placeholder="Ilość punktów">
							</div>

							<div v-if="summaryAnswers" class="custom-control custom-checkbox">
								<input type="checkbox" class="custom-control-input" :id="item.id" v-model="item.correct">
								<label class="custom-control-label" :for="item.id">Odpowiedź prawidłowa</label>
							</div>
							<button @click="removeValue(index)" class="btn btn-outline-secondary" type="button">Usuń</button>
						</div>
					</div>

					<div class="w-100 d-flex justify-content-end">
						<button type="button" @click="addValue()" class="btn btn-primary">Dodaj</button>
					</div>

				</div>
			</div>
		</div>
		<div class="col-auto">
			<OrderMenu @remove="$emit('remove')" @up="$emit('up')" @down="$emit('down')"></OrderMenu>
		</div>
	</div>
</template>

<script>
    import OrderMenu from '@/components/OrderMenu';
    import FieldMixin from '@/mixins/Field';
    import Helpers from '@/mixins/Helpers';
    import UploadFile from '@/components/UploadFile';
    import BtnDelete from '@/components/form-create/BtnDelete';

    export default {
        name: 'FieldRadio',
        mixins: [FieldMixin, Helpers],
        components: {OrderMenu, UploadFile, BtnDelete},
        props: ['form'],
        emits: ['remove', 'change'],
		data() {
            return {
                graphicsEntity: {
                    value: {
                        anyExtension: false,
                        extensions: [
                            {
                                title: "jpg",
                                checked: true
                            },
                            {
                                title: "jpeg",
                                checked: true
                            },
                            {
                                title: "png",
                                checked: true
                            },
                        ]
                    },
                },
				field: {
				    title: '',
					image: false,
                    feedback: '',
					values: [
						{
						    image: false,
                            correct: false,
                            feedback: '',
                            summary: false,
							id: this.makeToken()
						}
					]
				}
			}
		},
        computed: {
            summary() {
                return this.form.content.summary;
            },
            summaryAnswers() {
                return this.form.content.summary.type === 'answers';
            },
            summaryRange() {
                return this.form.content.summary.type === 'range';
            },
            summaryPoints() {
                return this.form.content.summary.type === 'points';
            },
            summaries() {
                return this.form.content.summary.summary;
            }
        },
		methods: {
            addValue() {
                this.field.values.push({
					image: false,
                    correct: false,
                    feedback: '',
                    summary: false,
					id: this.makeToken()
				})
			},
			removeValue(index) {
                if(this.field.values.length === 1) return;

                this.field.values.splice(index, 1);
			},
		}
    }
</script>