import makeToken from "../mixins/Helpers";

const pl = {
    start: 'Rozpocznij',
    startTest: 'Rozpocznij test',
    back: 'Wstecz',
    next: 'Dalej',
    check: 'Sprawdź',
    step: 'krok',
    of: 'z',
    question: 'Pytanie',
    correctAnswer: 'Prawidłowa odpowiedź!',
    correctAnswer2: 'Poprawna odpowiedź:',
    badAnswer: 'Odpowiedź błędna',
    yourAnswer: 'Twoja odpowiedź:',
    contactData: 'Dane <br>kontaktowe',
    lickertDown: 'Zdecydowanie NIE',
    lickertUp: 'Zdecydowanie TAK',
    personalDataAdmin: `Administratorami danych osobowych są: Uniwersytet WSB Merito we Wrocławiu, ul. Fabryczna 29-31, Uniwersytet WSB Merito  w Poznaniu, ul. Powstańców Wlkp 5, Uniwersytet WSB Merito w Gdańsku, al. Grunwaldzka 238A, Uniwersytet WSB Merito w Toruniu, ul. Młodzieżowa 31a, Uczelnia WSB Merito w Warszawie, ul. Łabiszyńska 25, zwane dalej Uczelnie WSB Merito`,
    thx: {
        correct: 'Gratulacje, wszystkie odpowiedzi są poprawne',
        bad: 'Niestety nie wszystkie Twoje odpowiedzi były poprawne.',
        score: 'Twój wynik to:',
        summary: 'Poniżej znajdziesz podsumowanie:'
    },
    validation: {
        required: 'To pole jest wymagane',
        email: 'Podaj poprawny adres email',
        phone: 'Podaj poprawny numer telefonu',
        min: 'Minimalna ilość znaków to: ',
        max: 'Maksymalna ilość znaków to: ',
    },
    contact: {
        contactData: 'Dane kontaktowe',
        firstName: 'Imię',
        lastName: 'Nazwisko',
        email: 'Email',
        phone: 'Telefon',
        location: 'Wybierz najbliższa Uczelnię WSB Merito',
    }
};

export default pl;