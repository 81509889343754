import makeToken from '../mixins/Helpers';

function contactModel(lang) {
    return {
        id: makeToken.methods.makeToken(),
        type: 'contact',
        value: {
            title: lang.contact.contactData,
            values: [
                {
                    id: makeToken.methods.makeToken(),
                    type: 'text',
                    value: {
                        title: lang.contact.firstName,
                        // value: 'Jan'
                    }
                },
                {
                    id: makeToken.methods.makeToken(),
                    type: 'text',
                    value: {
                        title: lang.contact.lastName,
                        // value: 'Jan',
                    }
                },
                {
                    id: makeToken.methods.makeToken(),
                    type: 'email',
                    value: {
                        title: lang.contact.email,
                        // value: 'Jan@jan.pl'
                    }
                },
                {
                    id: makeToken.methods.makeToken(),
                    type: 'phone',
                    value: {
                        title: lang.contact.phone,
                        // value: '123123123'
                    }
                },
                {
                    id: makeToken.methods.makeToken(),
                    type: 'select',
                    value: {
                        title: lang.contact.location,
                        // value: '123123123'
                    }
                },
            ]
        }
    }
};

export default contactModel;